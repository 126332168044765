
<template>
   <div>
     <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :filename="$t('property.pdf_filename')"
        :pdf-quality="2"
        :manual-pagination="false"
        :htmlToPdfOptions="{margin: 2}"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="propertyPdf"
        :html-to-pdf-options="{
        margin: 10
        }"
        >
        <template #pdf-content>
            <slot name="pdf-form"></slot>
        </template>
    </vue3-html2pdf>
        
   </div>
</template>
<script>
import Vue3Html2pdf from 'vue3-html2pdf'
export default {

    setup() {
        const hasGenerated = () => {

        }
        const onProgress = () => {

        }

        const hasStartedGeneration = () => {

        }
        return {hasGenerated};
    },
    methods: {
        /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
        generateReport () {
            this.$refs.propertyPdf.generatePdf()
        }
    },
 
    components: {
        Vue3Html2pdf
    }
}
</script>