<script>
import { h } from 'vue';

export default {
  props: {
    color: { type: String, default: 'blue' },
    display: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    property: { type: Object, default: () => {} }
  },
  setup() {},
  render() {
    return (
      <div>
        <br></br>
        <button>Increment</button>
        <span>Count:</span>
      </div>
    );
  }
};
</script>
