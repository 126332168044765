<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-content>
        <div class="table-wrapper">
          <page-title v-if="success" :pageTitle="$t('property.thanks')" />
          <page-title v-else :pageTitle="$t('property.create_property')" />
          <div ref="propertyContent">
            <property-form v-if="!success" :loading="loading" :submitAction="submitAction" />
          </div>

          <div v-if="success">
            <div class="ml-2 mt-3">
              <p class="mb-4">{{ $t('property.mail_sent') }}</p>
              <p>{{ $t('property.add_text') }}</p>
            </div>
            <va-button class="mt-3" size="large" data-cy="submit-button" @click="reloadPage" color="secondary">{{
              $t('property.create_another')
            }}</va-button>
          </div>
          <property-success-toast />
        </div>
      </va-card-content>
      <br />
      <va-card class="flex mb-4">
        <data-tables />
      </va-card>
    </va-card>
  </div>
</template>

<script>
import { CREATE_PROPERTY } from '@mdb/core/src';
import PropertyForm from './PropertyForm.vue';
import PageTitle from '@/components/text/PageTitle';
import { ref } from 'vue';
import Vue from 'vue';
import { h } from 'vue';
import { router } from 'vue-router';
import { getCurrentInstance, watch } from 'vue';
import PropertyPdf from './PropertyPdf';
import { useFormMutation } from '@/components/helpers/useFormMutation';
import PropertySuccessToast from './PropertySuccessToast';
import html2pdf from 'html2pdf.js';
import PropertyRentability from './PropertyRentability';
export default {
  components: { PropertyForm, PageTitle, PropertyPdf, PropertyRentability },
  setup() {
    const success = ref(false);
    const propertyContent = ref(null);

    const { mutate, loading, error, onDone, onError } = useFormMutation(CREATE_PROPERTY);

    onDone(() => {
      success.value = true;
    });

    //const rentability = PropertyRentability.render({ props: {} });
    //const rentability = h(PropertyRentability);

    // const test = () => {
    //   html2pdf()
    //     .from(propertyContent.value)
    //     .toPdf()
    //     .output('datauristring')
    //     .then(pdf => {
    //       // This logs the right base64
    //       console.log(pdf);
    //     });
    // };

    // const download = () => {
    //   //propertyContent.value.generatePdf();
    //   const options = {
    //     margin: 1,
    //     filename: 'document.pdf',
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: { dpi: 192, letterRendering: true },
    //     jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
    //   };

    //   let worker = html2pdf()
    //     .set(options)
    //     .from(propertyContent.value)
    //     .toPdf()
    //     .output('blob')
    //     .then(data => {
    //       return data;
    //     });
    // };

    const submitForm = async data => {
      //const pdf = JSON.stringify(propertyContent.value);

      const options = {
        margin: 1,
        filename: `property_${new Date().toLocaleString()}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      };

      let worker = html2pdf()
        .set(options)
        .from(propertyContent.value)
        .toPdf()
        .output('datauristring')
        .then(async pdf => {
          return await mutate({ data: { ...data, pdfContent: pdf } });
        });
    };

    // watch([createProperty.data], ([loadingNew], [loadingOld]) => {
    //   if(createProperty.data) {
    //             success.value = true
    //   }
    // });

    const submitAction = async data => {
      const result = await submitForm(data);
    };

    return {
      loading: loading,
      success,
      submitAction,
      propertyContent
      //rentability
    };
  },
  methods: {
    reloadPage() {
      this.$router.go();
    }
  }
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
